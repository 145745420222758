/* HeroSection.css */
.hero {
  background-image: url('IMG_3879.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  height: 100vh; /* 设置高度为全屏 */
}

.hero-box {
  background: rgba(0, 0, 0, 0.5); /* 半透明背景 */
  padding: 20px;
  border-radius: 10px;
  color: white;
  width: 100%; /* 确保内容宽度与父容器一致 */
  box-sizing: border-box; /* 确保padding包含在总高度和宽度内 */
  height: 100%; /* 确保高度与父容器一致 */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero-content {
  padding: 20px;
  border-radius: 10px;
  color: white;
}

.hero h1 {
  font-family: "Lucida Handwriting", cursive;
  font-size: 2rem;
  margin-bottom: 10px;
}

.hero p {
  font-size: 1rem;
  margin-bottom: 20px;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  width: 100%; /* 设置宽度为100% */
  max-width: 300px; /* 限制按钮的最大宽度 */
  box-sizing: border-box; /* 确保padding包含在总高度和宽度内 */
  margin: 0 auto; /* 居中对齐 */
}

.btn-primary {
  background-color: rgba(0, 0, 0, 0.5);
  color: rgb(255, 255, 255);
}

/* Media query for responsive design */
@media (max-width: 768px) {
  .hero {
    height: auto; /* 调整高度为自动 */
    padding-top: 80px; /* 确保有足够的间距避免与header重叠 */
  }

  .hero-content {
    padding: 10px; /* 调整padding */
  }

  .hero h1 {
    font-size: 1.5rem; /* 调整字体大小 */
  }

  .hero p {
    font-size: 0.875rem; /* 调整字体大小 */
  }

  .btn {
    font-size: 0.875rem; /* 调整按钮字体大小 */
  }
}

/* Header.css */
.header {
  background-color: rgba(255, 255, 255, 0.8); /* 透明白色背景 */
  border-bottom: 1px solid #ddd;
  padding: 20px 0;
  position: fixed; /* 固定在页面顶部 */
  width: 100%;
  top: 0; /* 确保在页面顶部 */
  z-index: 1000; /* 保证在其他内容之上 */
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 60px;
  margin-left: 10px;
}

.logo-text {
  font-family: 'Luckiest Guy', cursive;
  font-size: 3rem;
  color: #000000;
}

.nav {
  display: flex;
}

.nav-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
}

.nav-item {
  margin: 0;
}

.nav-link {
  text-decoration: none;
  color: #333;
  font-size: 1rem;
  position: relative;
  padding: 10px 0;
}

.nav-link:hover {
  color: #4CAF50;
}

.menu-toggle {
  display: none;
  cursor: pointer;
  font-size: 1.5rem;
}

/* Media query for responsive design */
@media (max-width: 768px) {
  .header-container {
    flex-direction: row; /* 修改为行方向 */
    align-items: center; /* 修改为居中对齐 */
  }

  .logo {
    flex-grow: 1; /* 使logo占据剩余空间 */
  }

  .nav {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .nav.open {
    display: flex;
  }

  .nav-list {
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .nav-link {
    padding: 10px;
    width: 100%;
    text-align: center;
  }

  .menu-toggle {
    display: block;
  }

  .logo-text {
    font-size: 2rem;
  }
}
