.header {
  background-color: rgba(255, 255, 255, 0.8); /* 透明白色背景 */
  border-bottom: 1px solid #ddd;
  padding: 20px 0;
  position: fixed; /* 固定在页面顶部 */
  width: 100%;
  top: 0; /* 确保在页面顶部 */
  z-index: 1000; /* 保证在其他内容之上 */
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 60px;
  margin-left: 10px;
}

.logo-text {
  font-family: 'Luckiest Guy', cursive;
  font-size: 3rem;
  color: #000000;
  text-decoration: none;
}

.nav {
  display: flex;
}

.nav-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
}

.nav-item {
  margin: 0;
}

.nav-link {
  text-decoration: none; /* 移除下划线 */
  color: #333;
  font-size: 1rem;
  position: relative;
  padding: 10px 0;
}

.nav-link:hover {
  color: #4CAF50;
}

.nav-link::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #4CAF50;
  transition: width 0.3s;
}

.nav-link:hover::after {
  width: 0; /* 确保没有下划线 */
}

.menu-toggle {
  display: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #333;
  margin-left: auto; /* 将汉堡菜单图标放到右边 */
}

/* Media query for responsive design */
@media (max-width: 768px) {
  .header-container {
    flex-direction: row; /* 将方向设置为横向 */
    justify-content: space-between;
    align-items: center;
  }

  .nav {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .nav.open {
    display: flex;
  }

  .nav-list {
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .nav-link {
    padding: 10px;
    width: 100%;
    text-align: center;
    text-decoration: none; /* 移除下划线 */
  }

  .menu-toggle {
    display: block;
    order: 2; /* 将汉堡菜单图标放在右边 */
  }

  .logo {
    order: 1; /* 将logo放在左边 */
  }

  .logo.hidden {
    display: none; /* 隐藏logo */
  }

  .logo-text {
    font-size: 2rem;
    text-decoration: none;
  }
}
