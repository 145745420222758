.gallery-page {
  text-align: center;
  padding: 20px;
  margin-top: 80px;
}

.gallery-h1 {
  font-family: 'Lucida Handwriting';
  font-size: 3rem;
  margin-bottom: 10px;
}

.gallery-subtitle {
  font-size: 1rem;
  color: gray;
  margin-bottom: 20px;
}

.category-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.category-tab {
  background: none;
  border: none;
  font-size: 1.2rem;
  margin: 0 10px;
  cursor: pointer;
}

.category-tab.active {
  font-weight: bold;
  border-bottom: 2px solid black;
}

.product-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 三列布局 */
  grid-template-rows: repeat(3, auto); /* 三行布局 */
  gap: 5px;
  width: 100%;
  justify-items: center;
}

.product-card {
  overflow: hidden;
  padding: 10px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 300px;
  transition: transform 0.3s ease; /* Add transition for smooth effect */
}

.product-card:hover {
  transform: scale(1.05); /* Zoom in the entire card slightly on hover */
}

.product-card img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover; /* Keep image aspect ratio */
  transition: transform 0.3s ease; /* Add transition for smooth effect */
  /* border-radius: 5px; */
}

.product-card:hover img {
  transform: scale(1.2); /* Zoom in the image slightly on hover */
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.pagination button {
  padding: 5px 10px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.load-more {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .gallery-h1 {
    font-size: 1.5rem;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .category-tabs {
    flex-direction: column;
    gap: 10px;
  }

  .product-container {
    width: 80%;
  }

  .product-grid {
    grid-template-columns: repeat(2, 1fr); /* 调整为两列布局 */
    grid-template-rows: repeat(5, auto); /* 调整为五行布局 */
    gap: 10px;
  }

  .product-card {
    width: 100%;
    height: auto;
  }

  .pagination {
    flex-direction: column;
    gap: 5px;
  }

  .pagination button {
    width: 20%;
  }
}
