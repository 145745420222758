/* CallToAction.css */
.cta {
    background-color: #4CAF50;
    color: white;
    text-align: center;
    padding: 50px 20px;
  }
  
  .cta-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .cta h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .cta .btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    background-color: white;
    color: #4CAF50;
  }

  .btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
}

  .btn-primary {
      background-color: rgba(0, 0, 0, 0.5);
      color: rgb(255, 255, 255);
      text-decoration: none; /* 取消下划线 */
  }

  .no-underline {
      text-decoration: none;
  }