.contact-page {
    text-align: center;
    padding: 20px;
    margin-top: 80px;
}

.contact-page h1 {
    font-family: "Lucida Handwriting";
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: black;
}

.contact-page p {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

form {
    max-width: 800px;
    width: 100%;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px;
}

form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

form input,
form select,
form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

form textarea {
    resize: vertical;
}

form span {
    color: red;
}

.btn-primary {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}

.btn-primary:hover {
    background-color: #45a049;
}
