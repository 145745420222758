/* AboutSection.css */
.about {
    padding: 50px 20px;
    text-align: center;
  }
  
  .about .container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .aboutlogo {
    font-family: 'Luckiest Guy', cursive;
    font-size: 3rem;
    color: #000000;
  }
  
  .about p {
    font-size: 1rem;
    line-height: 1.6;
  }
  