.gallery-section {
  background-color: rgba(24, 103, 33, 0.8);
  background-size: cover;
  background-position: center;
  padding: 40px 20px;
}

.gallery-section h2 {
  font-family: "Lucida Handwriting", cursive;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
  color: rgb(246, 244, 244);
}

.gallery-images {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px; /* 调整图片间距为 10px */
}

.gallery-images img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  margin: 5mm; /* 每个图片直接有 5mm 的距离 */
}

.gallery-btn-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.gallery-btn {
  padding: 10px 20px;
  border: 2px solid rgba(255, 255, 255, 0.8); /* 透明底色边框 */
  background-color: transparent;
  color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.gallery-btn:hover {
  background-color: rgba(255, 255, 255, 0.8);
  color: rgba(24, 103, 33, 0.8);
}
