.workshops-page {
  text-align: center;
  padding: 20px;
  margin-top: 80px; /* Add margin to avoid overlap with header */
}

.workshops-page h1 {
  font-family: 'Lucida Handwriting', cursive;
  font-size: 3rem;
  margin-bottom: 20px;
}

.workshops-page p {
  font-size: 1.25rem;
  margin-bottom: 20px;
}

.workshops-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.workshop-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  width: 550px; /* 增加卡片的宽度 */
  text-align: left;
}

.workshop-card img {
  width: 100%;
  max-height: 500px; /* 增加图片的最大高度 */
  object-fit: cover; /* 保持图片比例，裁剪溢出的部分 */
  border-radius: 10px;
  margin-bottom: 10px;
}

.workshop-card h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.workshop-card p {
  font-size: 1rem;
  margin-bottom: 5px;
}

.btn-primary {
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.btn-primary:hover {
  background-color: #45a049;
}
